<template>
    <div class="home">
        <el-row>
            <el-col style="text-align: left; " :xl="19" :lg="16">
                <div class="main">
                    <transition name="slide-fade">
                        <p v-if="showMain[0]" class="pm p1">光阴九载，<br>HIGHSHARP不忘初心。</p>
                    </transition>
                    <transition name="slide-fade">
                        <p v-if="showMain[1]" class="pm p2">主力团队积累了丰富的比特币矿机ASIC芯片设计经验。<br>在55纳米、16n纳米、10纳米、8纳米、N+1等多个制程100%流片成功。
                        </p>
                    </transition>
                    <transition name="slide-fade">
                        <p v-if="showMain[2]" class="pm p3">未尝败绩。</p>
                    </transition>
                    <transition name="fade">
                        <img src="../assets/img/logo_main.png" class="hs" v-if="showMain[3]">
                    </transition>
                </div>
            </el-col>
            <el-col width="32em" style="text-align: left;" :xl="5" :lg="8">
                <div class="aside">
                    <transition name="fade" v-for="i in sideContent.length">
                        <div v-if="showSide[i-1]" class="ps">
                            <div style="margin-right: 2em; padding-left: 0.5em; word-break: keep-all">
                                {{sideContent[i-1].date}}
                            </div>
                            <span>{{sideContent[i-1].text}}</span></div>
                    </transition>
                    <transition name="slide-fade">
                        <div v-if="showSide[sideContent.length]" class="ps"
                             style="background: none; text-align: center !important; display: block">TO BE CONTINUED
                        </div>
                    </transition>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        name: 'Intro',
        data() {
            return {
                showMain: [],
                showSide: [],
                sideContent: [
                    {date: '2013年', text: '在中芯国际量产了第一款55纳米制程比特币矿机ASIC芯片。自建自持比特币矿场。'},
                    {date: '2014年', text: '完成超过两万台比特币矿机的部署与运维。'},
                    {date: '2015年', text: '在台积电量产全球第一颗16纳米制程比特币矿机asic芯片。在美国休斯顿建设比特币矿场， 占地200亩。'},
                    {date: '2018年', text: '在三星半导体量产10纳米制程比特币矿机ASIC芯片，并成为三星半导体当年最大的中国客户。'},
                    {date: '2019年', text: '在三星半导体完成了10纳米制程比特币矿机ASIC芯片的第二次更新。'},
                    {date: '2021年', text: '完成新一代技术突破，在中芯国际开始N+1制程的比特币矿机芯片量产，功耗低于30W/Th。'},
                ]
            }
        },
        mounted() {
            for (let i = 0; i <= 3; i++) {
                setTimeout(() => {
                    this.showMain.push(true);
                }, 800 * (i + 1))
            }
            setTimeout(() => {
                for (let i = 0; i <= 7; i++) {
                    setTimeout(() => {
                        this.showSide.push(true);
                    }, 600 * (i + 1))
                }
            }, 1000)
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    .pm {
        color: #2c3e50;
        font-family: 微软雅黑;
        line-height: 1.2em;
        margin-bottom: 0;
    }

    .p1 {
        /*font-size: 2.5em;*/
        font-weight: bold;
    }

    .p2 {
        /*font-size: 1.5em;*/
    }

    .p3 {
        /*font-size: 2.5em;*/
        font-weight: bold;
    }

    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }

    div .main {
        margin-top: 35%;
    }

    @media (min-width: 1200px) {
        .ps {
            font-size: 1.5em;
        }

        .p1, .p3 {
            font-size: 2.5em;
        }

        .p2 {
            font-size: 1.5em;
        }

        .hs {
            height: 4em;
            margin-top: 5em;
        }

        .aside {
            min-height: 68em;
        }

        div .main {
            padding-left: 3em;
        }
    }

    @media (max-height: 1199px) {
        .ps {
            font-size: 1em;
        }

        .p1, .p3 {
            font-size: 2em;
        }

        .p2 {
            font-size: 1em;
        }

        .hs {
            height: 3em;
            margin-top: 2em;
        }

        .aside {
            min-height: 36em;
        }

        div .main {
            padding-left: 2em;
        }
    }

    .ps {
        color: #fff;
        font-family: 微软雅黑;
        margin-bottom: 2em;
        background-image: url("../assets/img/dot.png");
        background-repeat: no-repeat;
        background-size: 1.5em;
        /*font-size: 1.5em;*/
        line-height: 1.5em;
        display: flex;
    }

</style>

<style>

</style>
