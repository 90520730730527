<template>
    <div id="app">
        <el-container>
            <el-header>
                <img src="./assets/img/logo_banner.png" style="float: left; margin: 0.6em 0 0 1em; height: 2.6em">
                <el-menu style="float: right" mode="horizontal" :default-active="$route.path"
                         active-text-color="#6E92D1" text-color="#ffffff" @select="changeBg" :router="true">
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-menu-item index="/intro">公司介绍</el-menu-item>
                    <el-menu-item index="/prod">产品介绍</el-menu-item>
                    <el-menu-item index="/contact">联系我们</el-menu-item>
                </el-menu>
            </el-header>
            <el-main style="padding: 20px 0 0 0;">
                <router-view/>
            </el-main>
        </el-container>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                bg_home: require('./assets/img/bg_home.png'),
                bg_intro: require('./assets/img/bg_intro.png'),
                bg_prod: require('./assets/img/bg_prod.png'),
                bg_contact: require('./assets/img/bg_contact.png')
            }
        },
        methods: {
            changeBg(path) {
                let bg = `bg_${path == '/' ? 'home' : path.substring(1)}`;
                document.body.style.backgroundImage = `url(${this[bg]})`;
            }
        },
        mounted() {
            this.changeBg(this.$route.path)
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    html {
        height: 100%;
    }

    body {
        /*background-image: url("./assets/img/bg_home.png");*/
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .el-header {
        /*background-image: url("./assets/img/bg_header.png");*/
        /*background-repeat: repeat-x;*/
        background-color: rgba(13, 45, 80, 0.3);
        padding: 0;
    }

    .el-menu {
        background-color: rgba(255, 255, 255, 0) !important;
    }

    .el-menu-item {
        font-size: 1.2em !important;
        font-family: '微软雅黑' !important;
        font-weight: bold;
    }

    .el-menu-item:hover {
        color: #ccc !important;
    }
</style>
