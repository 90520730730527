<template>
    <div class="main">
        <img src="../assets/img/logo_main.png" class="hs">
        <p class="pm p1">地址：深圳市南山区粤海街道深圳湾科技生态园一期2栋C座508</p>
        <p class="pm p2">邮箱：<a href="mailto:info@highsharp.com">info@highsharp.com</a></p>
    </div>
</template>

<script>

    export default {
        name: 'Contact',
    }
</script>

<style scoped>
    p {
        color: #2c3e50;
        font-weight: bold;
    }

    div .main {
        text-align: left;
        float: left;
    }

    .hs {
        height: 4em;
        margin-top: 5em;
    }

    @media (min-width: 1200px) {
        div .main {
            margin-top: 30%;
            margin-left: 6em;
        }

        p {
            font-size: 2em;
        }
    }

    @media (max-height: 1199px) {
        div .main {
            margin-top: 20%;
            margin-left: 4em;
        }

        p {
            font-size: 1.5em;
        }
    }


</style>
