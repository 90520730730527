<template>
    <div class="home">
        <img src="../assets/img/logo_main.png" style="height: 5em; position: absolute; left: 42%; top: 45%">
    </div>
</template>

<script>

    export default {
        name: 'Home',
    }
</script>

<style>

</style>
