<template>
    <div class="home">
        <el-row>
            <el-col style="text-align: left; " :xl="24">
                <div class="main">
                    <img src="../assets/img/miner.png" class="miner">
                </div>
                <div class="desc">
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[0]">
                                <tr>
                                    <td style="font-size: 1.5em; opacity: 80%">Product</td>
                                    <td rowspan="3" style="font-size: 6em; font-weight: bold">C16</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 2.5em; font-weight: bold">产品性能</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 2.5em">Performance</td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="font-size: 1.5em;">高锐新品C16矿机，在性能上实现新的突破！</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[1]">
                                <tr>
                                    <td colspan="2" style="font-size: 1.5em;">整机算力</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="font-size: 3.75em; width: 3.75em">>120T</td>
                                    <td style="font-size: 1.5em">整机算力超过120Th，</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 1.5em">部分整机算力近130Th</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[2]">
                                <tr>
                                    <td colspan="2" style="font-size: 1.5em;">单位算力功耗</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="font-size: 3.75em; width: 4.5em"><30w/Th</td>
                                    <td style="font-size: 1.5em">单Th功耗小于30w，</td>
                                </tr>
                                <tr>
                                    <td style="font-size: 1.5em">部分机器单Th功耗接近25w。</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        name: 'Prod',
        data() {
            return {
                showTable: []
            }
        },
        mounted() {
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    this.showTable.push(true)
                }, 888 * (i + 1))
            }
        }
    }
</script>

<style scoped>
    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }

    .miner {
        margin: 0;
    }

    div .main {
        display: inline-block;
    }

    div .desc {
        color: #fff;
        display: inline-block;
        float: right;
        width: 40em;
    }

    @media (min-width: 1200px) {
        div .main {
            margin-top: 20%;
        }

        .miner {
            height: 45em;
        }

        div .desc {
            margin-top: 5em;
            margin-right: 5em;
        }

        table {
            margin-top: 3em;
        }
    }

    @media (max-height: 1199px) {
        div .main {
            margin-top: 12%;
        }

        .miner {
            height: 30em;
        }

        table {
            margin-top: 2em;
        }

        div .desc {
            margin-right: 4em;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style>

</style>
