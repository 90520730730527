import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Intro from "@/views/Intro";
import Prod from "@/views/Prod";
import Contact from "@/views/Contact";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/intro',
        name: 'Intro',
        component: Intro
    },
    {
        path: '/prod',
        name: 'Prod',
        component: Prod
    },
    {
        path: '/Contact',
        name: 'contact',
        component: Contact
    },
]

const router = new VueRouter({
    routes
})

export default router
